<template>
  <base-bg>
    <div>
      <top-nav-bar></top-nav-bar>
      <section class="page with-footer">
        <div class="container">
          <base-list-title :title="title"></base-list-title
          ><base-list
            :items="getInformations"
            :page="$routeNames.routeInformationPage"
            :showLikes="false"
          ></base-list>
        </div>
      </section>
    </div>
  </base-bg>
</template>

<script>
import { mapGetters } from "vuex";
import BaseListTitle from "../../components/UI/BaseListTitle.vue";
export default {
  components: { BaseListTitle },
  inject: ["activePage"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getInformations"]),
    title() {
      const string = `Hier findet Ihr Klimatipps zu allen Lebensbereichen`;
      return string;
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routeInformationsList;
  },
};
</script>

<style scoped></style>
